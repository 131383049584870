import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo.svg'; // Adjust the path as needed
import '../css/main.css'; // Adjust the path as needed
import '../css/style.css'; // Adjust the path as needed
import '../css/wow.css'; // Adjust the path as needed
import '../css/reposonsive.css'; // Adjust the path as needed
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from '../commonjs/redux/action';
import Swal from 'sweetalert2';
import WithToast from '../commonjs/withtoast';
import { logoutallapi } from '../commonjs/url';
import axiosInstance from '../commonjs/axiosinstand';
import { handleApiError } from '../commonjs/commonfunctions';
import { devandliveurl } from '../commonjs/url';
const logoutapiurl = logoutallapi();
const devurl = devandliveurl();
const Header = ({ showErrorToast }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isLoading4, setIsLoading4] = useState(false);
    const head = location.pathname;
    const userProfile = useSelector((state) => state.userProfile.userProfile);

    useEffect(() => {
        if (head !== '/auth/forgot') {
            dispatch(fetchUserProfile(showErrorToast));
        }
    }, [dispatch, head, showErrorToast]);

    const handleLogout = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = {
                        authuser: '0'
                    };
                    setIsLoading4(true); // Start loading before making the API request
                    const response = await axiosInstance.post(logoutapiurl, data);
    
                    if (response.status === 200) {
                        // Fetch the user profile and wait for it to complete
                        await dispatch(fetchUserProfile(showErrorToast));
    
                        // After fetchUserProfile is finished, stop the loading and navigate to the login page
                        setIsLoading4(false);
                        navigate('/login');
                    } else {
                        setIsLoading4(false); // Stop loading on failure
                        Swal.fire({
                            icon: 'error',
                            title: 'Logout Failed',
                            text: 'Something went wrong. Please try again later.',
                        });
                    }
                } catch (error) {
                    setIsLoading4(false); // Stop loading in case of error
                    handleApiError(error, null, null, true, showErrorToast);
                }
            }
        });
    };
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleBurgerMenuClick = () => {
        if (isMenuOpen) {
            document.body.classList.remove('mobile_menu_open');
        } else {
            document.body.classList.add('mobile_menu_open');
        }
        setIsMenuOpen(!isMenuOpen);
    };
    // Determine if the current pathname matches
    const isActive = (path) => location.pathname === path;

    return (
        <>
            {isLoading4 && (
                <>
                    <div className="loaderover">
                        <div className="loader"></div>
                    </div>
                </>
            )}
            <div className="content_wrapper">
                <header className="header">
                    <div className="c">
                        <div className="row">
                            <div className="col col_4">
                                <Link to="/" className="logo">
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="col col_75">
                                <div className="header_menu">
                                <ul>
                                        <li className={isActive(`${devurl}/index.php`) ? 'active' : ''}>
                                            <Link to={`${devurl}/index.php`}>Home</Link>
                                        </li>
                                        <li className={isActive(`${devurl}/booking-appoiment.php`)}>
                                            <Link to={`${devurl}/booking-appoiment.php`}>Book Appointment</Link>
                                        </li>
                                        <li className={isActive(`${devurl}/about.php`) ? 'active' : ''}>
                                            <Link to={`${devurl}/aboutus.php`}>About us</Link>
                                        </li>
                                        {userProfile.image ? (
                                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={userProfile.image} alt='profile' className='headerimage' />
                                                <p style={{ color: '#FFF' }}>{userProfile.name}</p>
                                            </li>
                                        ) : (
                                            <li className={isActive('/signup') ? 'active' : ''}>
                                                <Link to="/signup">Sign in</Link>
                                            </li>
                                        )}
                                        {userProfile.image && (
                                            <li>
                                                <p style={{ color: "#FFF", cursor: 'pointer' }} onClick={handleLogout}>Signout</p>
                                            </li>
                                        )}
                                        <li className={isActive(`${devurl}/contactus.php`) ? 'active' : ''}>
                                            <Link to={`${`${devurl}/contactus.php` }`}>Contact us</Link>
                                        </li>
                                    </ul>
                                    <i className="burger_menu" onClick={handleBurgerMenuClick}>
                                        <span className="lines"></span>
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className='mobile_menu'>
                    <div className="c">
                        <div className="row">
                            <div className="col col_4">
                                <Link to="/" className="logo">
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="col col_75">
                                <div className="header_menu">
                                    <ul>
                                        <li className={isActive(`${devurl}/index.php`) ? 'active' : ''}>
                                            <Link to={`${devurl}/index.php`}>Home</Link>
                                        </li>
                                        <li className={isActive(`${devurl}/contactus.php`) ? 'active' : ''}>
                                            <Link to={`${devurl}/contactus.php`}>Book Appointment</Link>
                                        </li>
                                        <li className={isActive(`${devurl}/aboutus.php`) ? 'active' : ''}>
                                            <Link to={`${devurl}/aboutus.php`}>About us</Link>
                                        </li>
                                        {userProfile.image ? (
                                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={userProfile.image} alt='profile' className='headerimage' />
                                                <p style={{ color: '#FFF' }}>{userProfile.name}</p>
                                            </li>
                                        ) : (
                                            <li className={isActive('/signup') ? 'active' : ''}>
                                                <Link to="/signup">Sign in</Link>
                                            </li>
                                        )}
                                        {userProfile.image && (
                                            <li>
                                                <p style={{ color: "#FFF", cursor: 'pointer' }} onClick={handleLogout}>Logout</p>
                                            </li>
                                        )}
                                        <li className={isActive(`${devurl}/contactus.php`) ? 'active' : ''}>
                                            <Link to={`${devurl}/contactus.php`}>Contact us</Link>
                                        </li>
                                    </ul>
                                    <i className="burger_menu" onClick={handleBurgerMenuClick}>
                                        <span className="lines"></span>
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default WithToast(Header);
